import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RailHorizontal from "../components/rail/railHorizontal";
import Pod from "../components/pod/pod";
import "../styles/style.css";

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        abstract
        showAbstract
        post
        authors
        avatars
        descriptions
        urls
        featured {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
      html
      fields {
        slug
      }
    }
  }
`;

const Story = props => {
  return (
    <Layout>
      <Metadata
        title={props.data.markdownRemark.frontmatter.title}
        description={props.data.markdownRemark.frontmatter.abstract}
        author={props.data.markdownRemark.frontmatter.authors[0]}
      />
      <section className="content" role="main">
        <Row className={`imageBox no-margin`} style={{ textAlign: "center" }}>
          <h1 className={"mt-2em"}>
            {props.data.markdownRemark.frontmatter.title}
          </h1>
          <Col className="justify">
            <span>
              Posted on {props.data.markdownRemark.frontmatter.date}{" "}
              <span> | </span> {props.data.markdownRemark.timeToRead} min read
            </span>
          </Col>
          {props.data.markdownRemark.frontmatter.featured && (
            <Img
              fluid={
                props.data.markdownRemark.frontmatter.featured.childImageSharp
                  .fluid
              }
              alt={props.data.markdownRemark.frontmatter.title}
            />
          )}
          <RailHorizontal
            title={props.data.markdownRemark.frontmatter.title}
            abstract={props.data.markdownRemark.frontmatter.post}
            articleUrl={`https://hopelabs.co.uk/stories/${props.data.markdownRemark.fields.slug}`}
          />
        </Row>
        {props.data.markdownRemark.frontmatter.showAbstract === "true" ? (
          <Row className={`presentation no-margin`}>
            <Col xs={12} sm={12} lg={9} xl={9} className={`mlr-auto`}>
              <p className={"lightText"}>
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "1.2rem",
                    marginRight: "1rem",
                  }}
                >
                  Summary.
                </span>
                {props.data.markdownRemark.frontmatter.abstract}
              </p>
            </Col>
          </Row>
        ) : null}
        <Row className={`no-margin mt-2em mb-2em`}>
          <div
            dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
            className={"presentation justify"}
          ></div>
        </Row>
        <Row className={`presentation no-margin`}>
          <Col xs={12} sm={12} lg={7} xl={7} className={`mlr-auto`}>
            {props.data.markdownRemark.frontmatter.authors.length > 0 &&
            props.data.markdownRemark.frontmatter.authors[0].length > 1 ? (
              <Pod
                authors={props.data.markdownRemark.frontmatter.authors}
                descriptions={
                  props.data.markdownRemark.frontmatter.descriptions
                }
                avatars={props.data.markdownRemark.frontmatter.avatars}
                urls={props.data.markdownRemark.frontmatter.urls}
              />
            ) : null}
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default Story;
