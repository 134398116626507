import React from "react";
import PropTypes from "prop-types";
import podStyle from "./pod.module.css";

const AuthorAvatar = props => {
  const initials = props.name
    .split(" ")
    .map(piece => piece[0])
    .join("");
  return props.avatar === "HL-short" ? (
    <a
      href={props.url}
      style={{ color: "var(--main-white)" }}
      target="_blank"
      rel="noreferrer"
    >
      <div className={podStyle.authorAvatar}></div>
    </a>
  ) : (
    <div className={podStyle.authorCircle}>
      <a
        href={props.url}
        style={{ color: "var(--main-white)" }}
        target="_blank"
        rel="noreferrer"
      >
        {initials}
      </a>
    </div>
  );
};

const Pod = props => {
  const authors = props.authors;
  const descriptions = props.descriptions;
  const avatars = props.avatars;
  const urls = props.urls;
  return (
    <ul className={podStyle.authorBio}>
      {authors.map((author, index) => (
        <li className={podStyle.listItem} key={`${author}-${index}`}>
          <div>
            <AuthorAvatar
              url={`${urls[index]}`}
              name={author}
              avatar={avatars[index]}
            />
          </div>
          <div>
            <span>
              {avatars[index] === "HL-short" ? (
                <a
                  href={urls[index]}
                  style={{
                    fontSize: "1.1em",
                    fontWeight: 500,
                    color: "var(--tertiary-color)",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {author}
                </a>
              ) : (
                <a
                  href={urls[index]}
                  style={{
                    fontSize: "1.1em",
                    fontWeight: 500,
                    color: "var(--tertiary-color)",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {author}
                </a>
              )}
              {` ${descriptions[index]}`}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Pod;

Pod.defaultProps = {
  authors: [""],
  descriptions: [""],
  avatars: [""],
  url: [""],
};

Pod.propTypes = {
  authors: PropTypes.array,
  descriptions: PropTypes.array,
  avatars: PropTypes.array,
  urls: PropTypes.array,
};

AuthorAvatar.defaultProps = {
  name: "",
  avatar: "",
  url: "",
};

AuthorAvatar.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  url: PropTypes.string,
};
