import React from "react";
import WindowSize from "../../utilities/WindowSize";
import railStyle from "./rail.module.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import { FiMail } from "@react-icons/all-files/fi/FiMail";
import { IconContext } from "@react-icons/all-files";

const IconStyle = props => {
  return (
    <IconContext.Provider value={{ className: "rail-icons" }}>
      {props.children}
    </IconContext.Provider>
  );
};

const RailHorizontal = props => {
  let window = WindowSize();

  return (
    <div className={`${railStyle.horzRail}`}>
      <ul className={`${window.width < 720 ? railStyle.mobile : null}`}>
        <li>
          <FacebookShareButton
            url={props.articleUrl}
            appid=""
            quote={props.title}
            hashtag="#hopelabs"
          >
            <IconStyle>
              <FaFacebook />
            </IconStyle>
            <div className={railStyle.iconLabel}>Post</div>
          </FacebookShareButton>
        </li>
        <li>
          <LinkedinShareButton
            url={props.articleUrl}
            title={props.title}
            summary={props.abstract}
            source={"https://hopelabs.co.uk"}
          >
            <IconStyle>
              <FaLinkedin />
            </IconStyle>
            <div className={railStyle.iconLabel}>Share</div>
          </LinkedinShareButton>
        </li>
        <li>
          <TwitterShareButton
            url={props.articleUrl}
            title={props.title}
            hashtags={["#hopelabs", "#socialenterprise", "#publicrelations"]}
            via="" // Twitter handle
            related={[""]} //Accounts to recommend following
          >
            <IconStyle>
              <FaTwitter />
            </IconStyle>
            <div className={railStyle.iconLabel}>Tweet</div>
          </TwitterShareButton>
        </li>
        <li>
          <WhatsappShareButton url={props.articleUrl} title={props.title}>
            <IconStyle>
              <FaWhatsapp />
            </IconStyle>
            <div className={railStyle.iconLabel}>Text</div>
          </WhatsappShareButton>
        </li>
        <li className="d-none d-md-block">
          <EmailShareButton
            url={props.articleUrl}
            subject={`${props.title} | Hopelabs.co.uk`}
          >
            <IconStyle>
              <FiMail />
            </IconStyle>
            <div className={railStyle.iconLabel}>Mail</div>
          </EmailShareButton>
        </li>
      </ul>
    </div>
  );
};

export default RailHorizontal;
